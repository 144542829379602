






































































































































































































import React, { useEffect, useState } from 'react'
import { useSprings, animated, to as interpolate } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'

import styles from './styles.module.css'

const cards = [
  {
    url: 'https://cdn-images-1.medium.com/max/1200/1*x_BI_Ww5aSJqNhpaywQZ5g.png',
    link: 'https://encord.com'
  },
  {
    url: 'https://seeklogo.com/images/L/linkedin-icon-logo-05B2880899-seeklogo.com.png',
    link: 'https://www.linkedin.com/in/danielfry1/'
  },
  {
    url: 'https://www.danoneinstitute.org/wp-content/uploads/2020/06/logo-rond-twitter.png',
    link: 'https://twitter.com/danfrydev'
  },
  {
    url: 'https://cdn-icons-png.flaticon.com/512/25/25231.png',
    link: 'https://github.com/danfry1'
  },

]

const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
})
const from = (_i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })
const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`

function Deck () {
  const [gone] = useState(() => new Set())
  const [props, api] = useSprings(cards.length, i => ({
    ...to(i),
    from: from(i),
  }))
  const bind = useDrag(({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
    const trigger = velocity > 0.4
    const dir = xDir < 0 ? -1 : 1
    if (!down && trigger) gone.add(index)
    api.start(i => {
      if (index !== i) return
      const isGone = gone.has(index)
      const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0
      const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0)
      const scale = down ? 1.1 : 1
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
      }
    })
    if (!down && gone.size === cards.length)
      setTimeout(() => {
        gone.clear()
        api.start(i => to(i))
      }, 2000)
  })
  return (
    <>
      {props.map(({ x, y, rot, scale }, i) => (
        <animated.div className={styles.deck} key={i} style={{ x, y }}>
          <animated.div
            {...bind(i)}
            style={{
              transform: interpolate([rot, scale], trans),
              backgroundImage: `url(${cards[i].url})`,
            }}
            onDoubleClick={() => window.open(cards[i].link, '_blank', 'noopener,noreferrer')}
          />
        </animated.div>
      ))}
    </>
  )
}

export default function App () {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

  useEffect(() => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }, []);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <p className={styles.name}>Daniel Fry</p>
        <p className={styles.encord}>Software Engineer<br /> @ Encord </p>
        <i className={styles.italic}>(double {isDesktop ? 'click' : 'tap'} on card to open)</i>
      </div>
      <Deck />
    </div>
  )
}
